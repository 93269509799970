import React from 'react';
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';
import '../global.css';
import '../Fonts/fontello-icon.css';;


const SpecialNeeds = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("specialNeedsSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-needs'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Effective Communication</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Core Communication Principles </span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Provides a comprehensive overview of essential communication skills, including clear messaging, active listening, and appropriate feedback mechanisms.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'> Effective Communication delves into the theoretical underpinnings that make communication successful, emphasizing the importance of understanding both verbal and non-verbal cues in various professional contexts.</p>
                        */} 

                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Strategic Interaction Techniques</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Introduces strategies for effective interpersonal interactions, highlighting the importance of adaptability and audience awareness in communication.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>This component equips learners with the tools to tailor their communication style to different situations, ensuring their message is always received as intended.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Enhances learning experiences for students with special needs.</p> */}

                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Conflict Communication Management</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Focuses on the specific application of communication skills in conflict resolution, teaching learners how to articulate disagreements constructively and seek common ground. </p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Understanding how to communicate effectively during disputes is key to maintaining professional relationships and fostering a collaborative work environment.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Aims to ensure every learner has the opportunity to thrive.</p> */}
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Tutors.jpeg' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Elevate Your Communicative Impact
                </p>
                <div className="width-100 ">
                  <img src='/images/Effective Communication Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>




          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Master essential principles to convey ideas clearly and engage in meaningful dialogue, improving information exchange and persuasion.
              </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Master essential principles to convey ideas clearly and engage in meaningful dialogue, improving information exchange and persuasion.
              </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Adapt your communication style to match the audience and situation, maximizing the impact of your messages.                 </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Navigate conflicts with strategic communication, fostering understanding and collaborative solutions.             </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Navigate conflicts with strategic communication, fostering understanding and collaborative solutions.              </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Develop active listening skills to enhance comprehension and response quality, ensuring productive interactions.                 </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/lesson image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Unlock the possibilities with Virtu Prep's compassionate and innovative approach to Special Needs Education.               </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/school image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Leverage communication techniques to strengthen team dynamics and advance professional relationships.           </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to learn how Soft Skills School’s Effective Communication program can refine your workplace communication, paving the way for enhanced personal and professional achievements.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/lesson image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default SpecialNeeds;

