// ScrollItems.js
import React from 'react';
import '../global.css';
import CommomScroll from './CommomScroll'
import Users from './Users';
import Research from '../research/research';

const ScrollItems = () => {
  const blogUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];

  const researchUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];
  return (
    <div className='container-fluid bg-dark p-0'>
      <div className="scroll-items bg-dark vp-container pt-4">
        {/* Your scroll items/content go here */}
        <div class="row  gap-5 mt-5">
          <div class="col-md">
            <span class='custom-large-heading light-col'>Features</span>
          </div>
          <div class="col-md">
            <span class='montser-sml-LightItalic light-col ft-section-heading'>Elevate your professional growth with our comprehensive Soft Skills School programs. From mastering conflict resolution to enhancing emotional intelligence, we equip you with the cutting-edge skills essential for leadership and personal development. Embrace the opportunity to transform challenges into stepping stones for success.</span>
          </div>
        </div>

        <div className="row text-yellow pt-2 gx-5 img-spacing">
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/adaptiveresilience " className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-2 common-textshadow ">Adaptive Resilience</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Personalized, adaptive, engaging</span>
                </div>
                <img src='images/Adaptive Resilience Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>

              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/emotionalintelligence" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text scroll-text common-textshadow  montser-mdm-2 ">Strategic Decision-Making</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Sharpen focus, Supercharge memory</span>
                </div>
                <img src='images/Strategic Decision-Making Image Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/effectivecommunication" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow montser-mdm-2  ">Conflict Resolution</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Education for all abilities</span>
                </div>
                <img src='images/Conflict Resolution Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/conflictresolution" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2">Effective Communication</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Embrace cultural insights</span>
                </div>
                <img src='images/Effective Communication Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/strategicdecisionmaking" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">Leadership Development</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Grow Your brain</span>
                </div>
                <img src='images/Leadership Development Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/leadershipdevelopment" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">Emotional Intelligence</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Streamlined educational administration</span>
                </div>
                <img src='images/Emotional Intelligence Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-12">
            <hr className='mt-8rem'></hr>
          </div>
        </div>
      </div>
      <CommomScroll title="Blog" urls={blogUrls} />
      <Research />
      <Users />
    </div>

  );
};

export default ScrollItems;