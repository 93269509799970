import { React,useEffect } from 'react';
import VideoBackground from '../components/VideoBackground';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './aboutus.css';
import '../global.css';
import '../Fonts/fontello-icon.css';;

const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                       
                       
                       
                        <div className='mt-2 pt-3'>
                        <heding className='montser-lrg-bold align-items-center py-4'>Nurturing Emotional Intelligence and Interpersonal Success</heding>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Soft Skills School, your pathway to mastering the essential interpersonal and emotional skills needed in today's interconnected and dynamic professional world. Recognizing the crucial role of social-emotional learning (SEL) in personal and professional development, our platform, powered by Virtu Prep technology, offers a comprehensive curriculum designed to enhance not just traditional soft skills but also the foundational elements of SEL. By integrating emotional intelligence, empathy, communication, and leadership, we prepare individuals to excel in any professional setting and foster positive, productive relationships.
                        </p>
                       
           
                        <heding className='montser-lrg-bold align-items-center py-4'>Our Vision: Elevating Interactions Through Emotional and Social Intelligence</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Our mission is to empower professionals and individuals with the soft skills and SEL competencies necessary to navigate the complexities of modern workplaces and society. We believe that success is achieved not only through technical expertise but also through the ability to understand, manage, and express emotions constructively, engage in meaningful social interactions, and make responsible decisions. 
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Soft Skills School is committed to transforming participants into emotionally intelligent leaders who can inspire, collaborate, and thrive in diverse environments.
                        </p>
                        

                        <heding className='montser-lrg-bold align-items-center py-4'>Comprehensive Development for Professional Excellence:</heding>

                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Emotional Intelligence Mastery: Enhance your emotional awareness and control, enabling more effective communication, stress management, and empathy. 
                        </p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Collaborative Team Dynamics: Cultivate skills for constructive teamwork and conflict resolution, underpinned by a deep understanding of social cues and relationships.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Inspirational Leadership: Develop the ability to lead with compassion and vision, leveraging emotional insights to motivate and support your team.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap'>
                        Mindful Decision-Making: Learn to make informed, ethical decisions by considering emotional impacts and fostering a culture of responsibility and reflection.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap'>
                        Adaptive Communication: Improve your ability to articulate thoughts and understand others, adapting your communication style to various social and professional contexts.
                        </p>
                       


                        {/* <heding className='montser-lrg-bold align-items-center py-4'>A Holistic Educational Experience: Inclusivity and Accessibility:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Our inclusive platform addresses a spectrum of educational needs. We cater to students with learning challenges, homeschoolers, and those in disadvantaged and underserved communities with equal dedication. Our tools – ranging from gamified learning environments to interactive whiteboards and virtual classrooms – are designed to engage, inspire, and educate in a way that traditional classrooms cannot.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        We understand the role of parents and guardians in the educational journey. Our platform includes tools for parental involvement, offering progress reports and performance dashboards to keep them informed and engaged.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Resilience and Adaptability: Build resilience through emotional regulation and adaptability, preparing you to face challenges with confidence and flexibility.
                        </p> */}
                      
                        
                    

                        {/* <heding className='montser-lrg-bold align-items-center py-4'>Beyond Academics: Fostering Life Skills and Personal Growth: </heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Virtu Prep’s mission extends beyond academic success. We place a strong emphasis on developing essential life skills such as problem-solving, resilience, and self-directed learning. Our aim is to equip learners not just for academic achievements but for real-world challenges, fostering personal and professional growth.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Security, privacy, and continuous innovation are foundational to our operations. We're dedicated to regularly enhancing our platform, informed by user feedback, research, and the latest technological advancements, all while ensuring the utmost data security and privacy.
                        </p> */}
                       

                        <heding className='montser-lrg-bold align-items-center py-4'>Join Our Community of Empathetic Professionals</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        At Soft Skills School, we go beyond traditional skill development to address the heart of interpersonal effectiveness: social and emotional learning. By joining us, you're not just advancing your career; you're embarking on a journey of personal growth and transformation, equipped to make a positive impact in your professional and personal life.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Discover the power of integrating SEL with soft skills training to unlock your full potential. Welcome to a new era of professional development. Welcome to Soft Skills School.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Click 'Meet Us' to learn more about our unique approach to soft skills and social-emotional learning, and how it can elevate your career and life.
                            </p>

                        </div>

                    </div>
                </div>
                <Footer />
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default Aboutus;

