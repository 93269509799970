// Students.js
import React from 'react';
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../global.css';
import '../Fonts/fontello-icon.css';;


const BlogDashboard = () => {
    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("blogdashSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-brain'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <section>
                        <h4 className='mb-3 custom-large-heading section-margin'>Blogs</h4>
                        <hr className='mt-5'></hr>
                    </section>
                    <div className=''>
                    <div className="row text-yellow pt-2 gx-5 img-spacing">

                    <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog4" className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                        <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2 ">Shadows Over the Academy</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Unmasking Double Standards</span>
                                    </div>
                                    <img src='images/Blog_Shadows Over the Academy_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                                </a>
                            </div>
                        </div>

                        {/* <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog2" className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                        <span className="scroll-item mb-1 text-yellow scroll-text scroll-text common-textshadow  montser-mdm-2 ">Education's Role in Radicalization and Antisemitism</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">A Deep Dive into Israel-Palestine</span>
                                    </div>
                                    <img src='images/Blog_Educations Role in Radicalization_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                                </a>
                            </div>
                        </div> */}

                        <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog3" className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                        <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow montser-mdm-2  ">Effective Repetition</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">The Timeless Tool  </span>
                                    </div>
                                    <img src='images/Blog_Effective Repetition_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog7 " className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                    <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">The Neuroscience of Focus in Education</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Unveiling a New Paradigm for Learning</span>
                                    </div>
                                        <img src='images/Blog_Neuroscience of Focus_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog1 " className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                        <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-2 common-textshadow ">AI Tutors</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Enhancing the Learning Experience</span>
                                    </div>
                                        <img src='images/Blog_AI Tutors_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog6" className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                        <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">The Memory Matrix</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Grow Your Brain!</span>
                                    </div>
                                    <img src='images/Blog_Memory Matrix_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                                </a>
                            </div>
                        </div>
                    
                        <div className="col-md-6 col-lg-4 my-4">
                            <div className="card border-0 text-yellow bg-transparent">
                                <a href="/blog5" className="text-decoration-none" >
                                    <div className='d-flex flex-column headings'>
                                        <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">The Learning Kaleidoscope</span>
                                        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Learning How To Learn</span>
                                    </div>
                                    <img src='images/Blog_The Learning Kaleidoscope_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                                </a>
                            </div>
                        </div>                      
                        <div className="col-12">
                            <hr className='mt-8rem'></hr>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer />
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default BlogDashboard;

