// Students.js
import { React,useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';
import '../global.css';
import '../Fonts/fontello-icon.css';;


const Braintraining = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("braintrainingSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-brain'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Strategic Decision-Making</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Informed Choices for Long-Term Success </span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Equips professionals with a framework for making strategic decisions that align with both immediate challenges and long-term objectives.</p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>This feature emphasizes the importance of gathering and analyzing information, predicting outcomes, and considering the broader impact of decisions on professional and organizational goals.</p>
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Supports healthy cognitive aging.</p> */}
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Risk Evaluation and Mitigation</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Focuses on identifying potential risks associated with various decision paths, equipping individuals with the skills to assess and manage these risks effectively.</p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Strategic Decision-Making ensures that professionals can navigate uncertainties with confidence, making choices that balance opportunity and caution.</p>
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Offers engaging, neuroscience-based learning techniques.</p> */}

                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Ethical and Impactful Decision Processes</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Underlines the necessity of integrating ethical considerations into every decision, promoting transparency, accountability, and social responsibility.</p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>This aspect of Strategic Decision-Making fosters trust and integrity in professional interactions and decisions, contributing to sustainable business practices and positive workplace cultures.</p>
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Promotes long-lasting mental health and well-being.</p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Can't Say No Value Proposition</p> */}
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          
          </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2  vp-container common-textshadow mb-3 text-center lh-lg ft-4vh'>
                Elevate Your Leadership with Insightful Decision-Making
                </p>
                <div className="width-100 ">
                  <img src='/images/Strategic Decision-Making Image Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Harness the power of strategic thinking to make informed decisions that drive your career and organization forward.           </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 d-flex  me-auto'>
                    <img src='/images/school image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Harness the power of strategic thinking to make informed decisions that drive your career and organization forward.           </p>
                    </div>
                </div>
              </div>
            </div>
          </section>
            
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Navigate risks with a comprehensive understanding of potential impacts, preparing you to manage challenges proactively.                 </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 d-flex ms-auto'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Virtu Prep's brain training empowers you to unlock your full potential while combating cognitive neurodegeneration.           </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 d-flex  me-auto'>
                    <img src='/images/lesson image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Commit to ethical decision-making, reinforcing your role as a trustworthy and responsible leader in your professional community.           </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Leverage analytical tools and frameworks to evaluate options and outcomes, enhancing your ability to choose wisely in complex situations.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 d-flex  ms-auto'>
                    <img src='/images/school image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Choose Virtu Prep's brain training and elevate your mind to new heights of success and long-lasting mental health.                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 d-flex  me-auto'>
                    <img src='/images/lesson image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Click 'Meet Us' to discover how Soft Skills School’s Strategic Decision-Making program can transform your approach to decision-making, ensuring that your choices lead to meaningful and positive outcomes for you and your organization.             </p>
                    </div>
                </div>
              </div>
              <div className="row">
  <div className='col-sm-7'></div>
  <div className='col-sm-3'>
    <div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-end footer'>
      <MyPopupbutton/>
    </div>
  </div>
</div>

            </div>
          </section>


          
          {/* <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to discover how Soft Skills School’s Strategic Decision-Making program can transform your approach to decision-making, ensuring that your choices lead to meaningful and positive outcomes for you and your organization.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 d-flex  ms-auto'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                         <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section> */}



        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Braintraining;

