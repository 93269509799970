import React from 'react';
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';
import '../global.css';
import '../Fonts/fontello-icon.css';;


const Culture = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("cultureSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-culture'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Conflict Resolution</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Mastering Dispute Resolution </span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Equips professionals with the skills to identify, understand, and resolve conflicts in a constructive and timely manner. </p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Conflict Resolution focuses on fostering open communication and mutual respect, ensuring that disagreements become opportunities for growth and collaboration rather than sources of division.</p>
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Incorporates culturally relevant content in educational materials.</p> */}

                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Negotiation and Mediation Techniques</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Introduces proven negotiation and mediation strategies to help resolve disputes effectively, preserving professional relationships.</p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>By applying these techniques, individuals can find common ground and achieve mutually beneficial outcomes, enhancing team cohesion and productivity..</p>
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Enhances global awareness in learners.</p> */}

                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Emotional Intelligence in Conflicts</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Emphasizes the role of emotional intelligence in managing and resolving conflicts. </p>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Understanding and empathizing with the perspectives of others facilitates a more compassionate and effective resolution process, ensuring all parties feel heard and valued.</p>
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Empowers communities by celebrating cultural richness.</p> */}
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Culture Focused 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Navigate Workplace Conflicts with Confidence
                </p>
                <div className="width-100 ">
                  <img src='/images/Conflict Resolution Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
         
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Develop the ability to resolve disputes constructively, turning challenges into opportunities for team strengthening.       </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-7'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img me-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                By incorporating culturally relevant content, adapting teaching methods to address learning gaps, and offering diverse course offerings,Virtu Prep promotes understanding, empathy, and global awareness.                      </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Apply negotiation and mediation techniques to reach agreements that satisfy all involved parties.                      </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Utilize emotional intelligence to navigate conflicts with empathy, improving outcomes and relationships.

                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                while empowering diverse communities. Choose Virtu Prep and celebrate the richness of cultural diversity in education.                </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-6'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/lesson image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Foster a positive work environment by promoting open communication and mutual respect among team members.                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Enhance your professional reputation as a collaborative and effective problem-solver.
                    
        </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/school image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>
          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                while empowering diverse communities. Choose Virtu Prep and celebrate the richness of cultural diversity in education.                </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-6'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/lesson image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Click 'Meet Us' to explore how Soft Skills School’s Conflict Resolution program can empower you to manage workplace conflicts skillfully, maintaining harmony and driving collective success.              </p>
                    </div>
                </div>
              </div>
              <div className="row">
  <div className='col-sm-7'></div>
  <div className='col-sm-3'>
    <div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-end footer'>
      <MyPopupbutton/>
    </div>
  </div>
</div>

            </div>
          </section>


        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Culture;

